import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, allowedRoles }) => {
  const user = useSelector((state) => state.auth.user);
  const role = user?.role;

  if (!role || !allowedRoles.includes(role)) {
    // Redirect to a different route if access is denied
    return <Navigate to="/unauthorized" />;
  }

  return children;
};

export default PrivateRoute;
